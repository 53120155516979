var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    staticClass: "amounts-incl-vat-by-vat",
    domProps: { innerHTML: _vm._s(_vm.amountsInclVatByVat) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }